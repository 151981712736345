export enum ServicePageSlugCodes {
  HomeEmergencyInsurance = "home-emergency",
  RentGuaranteeInsurance = "rent-guarantee-insurance",
  ContentsInsurance = "content-insurance",
  ElectricalInstallationConditionReport = "eicr",
  ProfessionalPhotography = "property-photography",
  InventoryCheckInCheckOut = "inventory",
  GasSafety = "gas-safety",
  EnergyPerformanceCertificate = "epc",
  DepositReplacement = "deposit-replacement-tenant",
}
